<template>
  <div>
    <modal
      ref="modal"
      class-name="w-full md:w-6/10 lg:w-5/12 xl:w-4/12 pt-10 px-5 sm:px-10"
      @close="close"
    >
      <div v-if="merchant" class="flex flex-col mb-5">
        <div class="mb-4 flex flex-col">
          <h2 class="text-4xl font-bold">
            {{ getKey('business_name') | sentenceCase }}
          </h2>
          <div class="inline-flex items-center">
            <span class="text-sm">{{ getKey('email') }}</span>
            <span class="h-4 w-px border border-black mr-3 ml-3" />
            <span class="text-sm">
              {{ getKey('phone') }}
            </span>
          </div>
          <span class="text-sm mt-1 font-semibold" :class="status">
            {{ getKey('status') | sentenceCase }}
          </span>
        </div>
        <div class="inline-flex items-center">
          <router-link
            :to="{ name: 'merchant-profile', params: { id: merchant.id } }"
            target="_blank"
            class="btn btn-blue btn-sm"
          >
            View Profile
          </router-link>

          <button
            v-if="canDo('credpal:can_delete_merchants')"
            class="btn btn-red btn-sm ml-2"
            @click="$refs.deleteConfirmModal.open()"
          >
            Delete
          </button>
        </div>

        <div class="w-full rounded-sm bg-gray-200 p-6 mt-5 flex flex-col">
          <h2 class="text-base font-bold tracking-tighter mb-5">
            ACCOUNT DETAILS
          </h2>

          <div class="grid grid-cols-2 gap-5">
            <div class="col-span-1 text-11 border-r">
              <div class="flex flex-col mb-2">
                <h2 class="text-12 font-semibold tracking-tighter mb-1">
                  Admin Fullname
                </h2>
                {{ `${getKey('first_name')} ${getKey('last_name')}` }}
              </div>

              <div class="flex flex-col">
                <h2 class="text-12 font-semibold tracking-tighter mb-1">
                  Category
                </h2>
                {{ getKey('industry').name }}
              </div>
            </div>
            <div class="col-span-1 text-11">
              <div class="flex flex-col mb-2">
                <h2 class="text-12 font-semibold tracking-tighter mb-1">
                  Address
                </h2>
                {{ getKey('address') }}
              </div>

              <div class="flex flex-col">
                <h2 class="text-12 font-semibold tracking-tighter mb-1">
                  State
                </h2>
                {{ getKey('state') }}
              </div>
            </div>
          </div>
        </div>

        <div class="w-full rounded-sm bg-gray-200 p-6 mt-5 flex flex-col">
          <h2 class="text-base font-bold tracking-tighter">
            VENDOR ACCESS
          </h2>
          <div class="flex flex-wrap mt-5" v-if="vendorAccess.length">
            <div
              v-for="(access, i) in vendorAccess"
              :key="i"
              class="text-sm bg-blue-200 text-blue-500 px-6 py-2 rounded font-semibold mr-3"
            >
              {{ access }}
            </div>
          </div>
          <div v-else class="text-12 mt-1">
            {{ getKey('business_name') }} has no vendor access
          </div>
        </div>
      </div>
    </modal>

    <modal ref="deleteConfirmModal" class-name="w-11/12 md:w-4/12 xl:w-3/12">
      <div class="mb-5">
        <div class="text-xl font-bold mb-3">
          Delete This Merchant?
        </div>

        <div class="font-light text-gray-700 text-sm">
          Are you sure, you want to perform this action?
        </div>
      </div>

      <template v-if="getFormError(deleteMerchant)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert icon">!</span>
          <span>{{ getFormError(deleteMerchant) }}</span>
        </div>
      </template>

      <div class="flex items-center">
        <button
          type="button"
          class="btn btn-blue"
          @click.prevent="$refs.deleteConfirmModal.close"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-red ml-3"
          @click.prevent="confirmMerchantDelete"
          :disabled="deleteMerchant.loading"
        >
          <sm-loader v-if="deleteMerchant.loading" class="sm-loader-white" />
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    merchant: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      closeTimeout: null,
      deleteMerchant: this.$options.resource({})
    };
  },
  computed: {
    businessName() {
      return this.merchant?.business_name;
    },
    image() {
      return this.merchant?.image;
    },
    description() {
      return this.merchant?.descriptions;
    },
    vendorAccess() {
      return this.merchant?.vendorAccess?.length
        ? this.merchant?.vendorAccess?.map(access => access?.name)
        : [];
    },
    status() {
      let color;
      switch (this.merchant?.status) {
        case 'approved':
          color = 'text-green-500';
          break;
        case 'declined':
          color = 'text-red-500';
          break;
        case 'pending':
        default:
          color = 'text-orange-500';
          break;
      }
      return color;
    },
    industry() {
      return this.merchant?.industry;
    }
  },
  methods: {
    close() {
      if (this.$refs.modal.isOpen) {
        this.$refs.modal.close();
      }
      this.closeTimeout = setTimeout(() => {
        this.$emit('close');
      }, 1000);
    },
    open() {
      clearTimeout(this.closeTimeout);
      this.$refs.modal.open();
      this.$emit('open');
    },
    getKey(key) {
      return (
        this.merchant?.[key] ??
        this.merchant?.descriptions?.[key] ??
        'Data Not Provided'
      );
    },
    async confirmMerchantDelete() {
      this.deleteMerchant.loading = true;
      await this.sendRequest(
        'admin.merchants.deleteMerchant',
        this.merchant?.id,
        {
          success: () => {
            this.close();
            this.$refs.deleteConfirmModal.close();
            this.$nextTick(() => this.$parent?.reloadTable());
          },
          error: error => (this.deleteMerchant.error = error)
        }
      ).finally(() => (this.deleteMerchant.loading = false));
    }
  }
};
</script>
